import axios from 'axios';
import { constants, gql } from '@skunexus/utils';
import { helpers } from '@skunexus/custom-fields';

const {
    CONFIG: { API_PATH },
} = constants;

export async function getPrescriptionItemData(id) {
    let response = await fetch(`${API_PATH}/orders/${id}/prescription`);

    return await response.json()
};

export async function getInvoiceData(setDataFn, id) {
    try {
        let request = await fetchInvoice(id);
        let data = await request.data;

        const { data: {order: {details}} } = data;
        const { customValues, items, id: invoiceId, label, shippingAddress, billingAddress } = details;

        const storeNo = findCustomFieldValue(customValues, "store_no");
        const containsPrescriptionGlasses = findCustomFieldValue(customValues, "contains_prescription_glasses");
        const containsLenses = findCustomFieldValue(customValues, "contains_lenses");

        const invoiceData = {
            shippingAddress: {...shippingAddress},
            billingAddress: {...billingAddress},
            barcodes: {
                items: [...items],
                storeNo,
                typeCode: '3of9'
            },
            containsPrescriptionGlasses,
            containsLenses,
            invoiceId,
            label
        }

        if (containsLenses) {
            let request = await getPrescriptionItemData(invoiceData.invoiceId);
            let data = await request;

            setDataFn({
                ...invoiceData,
                lensesPrescription: {
                    lenses: {...data.prescription.lenses},
                }
            });

            window.print();
            return;
        }

        setDataFn(invoiceData);
        window.print();
    } catch (e) {
        console.log('catch', e)
    }
}

export function fetchDetails({ id, domain, showItemsImages }) {
    const imagePart = showItemsImages ? `image { url type }` : '';

    return gql.getGraphQLDetails({
        url: `${API_PATH}/query`,
        config: {
            domain,
            detailsField: 'details',
        },
        fields: `
      id
      label
      barcodeType
      barcodeValue
      ${helpers.apiHelper.customValuesField}
      created_at
      updated_at
      current_state
      relatedOrder {
        label
        id
      }
      assignedUser {
        id
        label
      }
      assignedCart {
        id
        label
      }
      fulfillFrom {
        id
        label
        is_active_cycle_count
      }
      items {
        id
        product {
          id
          name
          sku
        }
        barcodes {
          barcodeType
          barcodeValue
        }
        quantity
        location {
          id
          status
          barcodeValue
          barcodeType
          label
          warehouse{
            id
            label
          }
        }
        ${imagePart}
      }
    `,
        payload: { id },
    });
}

export function moveToPack({ id, data = {} }) {
    return axios.post(`${API_PATH}/fulfillment/${id}/transition/pack`, {
        ...data,
    });
}

export function fetchUserFulfillmentToPick({ domain, userId }) {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain,
        },
        gridQueryPart: `grid(
          sort: { updated_at: ASC },
          limit: { size: 9999, page: 1 },
          filter: {
              assignedUser: {id: {operator: eq, value: "${userId}"}}, 
              current_state: {operator: eq, value: "pick"}
          } 
          ) {
              rows { id }
          }`,
    });
}

export function fetchCarts(fulfillFrom) {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'cart',
        },
        gridQueryPart: `grid(
          sort: { label: ASC },
          limit: {size: 9999, page: 0},
          filter: {
            fulfillFrom: {
              label: { operator: like, value: "%${fulfillFrom}%" }
            }
          }
        ) {
          rows { id, label, active, barcode_value, totes_enabled }
        }`,
    });
}

export function assignCartId(id, cartId) {
    return axios.put(`${API_PATH}/fulfillment/${id}/cart`, {
        id: cartId,
    });
}

export function unAssignCartId(id) {
    return axios.delete(`${API_PATH}/fulfillment/${id}/cart`);
}

export function fetchUsers() {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'user',
        },
        gridQueryPart: `grid (
          sort: { first_name: ASC },
          limit: {size: 9999, page: 0},
          filter: { roles: { operator:nin, value:"vendor user" } },
        ) { rows {
          id,
          label: name,
          first_name,
          last_name,
        } }`,
    });
}

export function assignUser(id, userId) {
    return axios.put(`${API_PATH}/fulfillment/${id}/user`, { id: userId });
}

export function unAssignUser(id) {
    return axios.delete(`${API_PATH}/fulfillment/${id}/user`);
}
