import {
    TITLE_PREFIX,
    ORDERS_INDEX_ROUTE,
    ORDERS_FORM_PATH,
    ORDERS_CREATE_FORM_ROUTE,
    ORDERS_DETAILS_PATH,
    ORDERS_DETAILS_ROUTE,
} from './consts';
import {
    SHIP_DETAILS_ROUTE,
    SHIP_GROUP_PICK_DETAILS_ROUTE,
    PICKUP_DETAILS_ROUTE,
    PICKUP_GROUP_PICK_DETAILS_ROUTE,
    CANCELLATION_DETAILS_ROUTE,
} from '../fulfillment/consts';
import { VENDOR_DETAILS_ROUTE } from '../vendors/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { SHIPMENT_DETAILS_ROUTE } from '../shipment/consts';
import { PO_DETAILS_ROUTE } from '../purchaseOrders/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';
import UserModel from '../../../UserModel';

export default () => {
    const routers = {
        orders: {
            exact: true,
            path: ORDERS_INDEX_ROUTE,
            title: `${TITLE_PREFIX}s`,
            permission: ['orders.index'],
            breadcrumbConfig: { title: `${TITLE_PREFIX}s`, clearPath: true },
            loader: () => import('../../../packages/skunexus-fe-orders/src/core'),
            props: () => ({
                detailsPageRoute: ORDERS_DETAILS_ROUTE,
                addFormPageRoute: ORDERS_CREATE_FORM_ROUTE,
            }),
        },
        orderForm: {
            exact: true,
            path: ORDERS_FORM_PATH,
            title: `${TITLE_PREFIX} Form`,
            permission: ['orders.create'],
            breadcrumbConfig: { title: 'Create', isReplaceble: true },
            props: () => ({
                indexPageRoute: ORDERS_INDEX_ROUTE,
                detailsPageRoute: ORDERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/order-form'),
        },
        orderDetails: {
            exact: true,
            path: ORDERS_DETAILS_PATH,
            title: `${TITLE_PREFIX} Details`,
            permission: ['orders.show'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            loader: () => import('../../../packages/smst-fe-order-details-module/src/core'),
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                fulfillmentDetailsPageRouteShip: SHIP_DETAILS_ROUTE,
                fulfillmentDetailsPageRoutePickup: PICKUP_DETAILS_ROUTE,
                fulfillmentGroupDetailsPageRouteShip: SHIP_GROUP_PICK_DETAILS_ROUTE,
                fulfillmentGroupDetailsPageRoutePickup: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                fulfillmentDetailsPageRouteCancellation: CANCELLATION_DETAILS_ROUTE,
                vendorDetailsPageRoute: VENDOR_DETAILS_ROUTE,
                purchaseOrderRoute: PO_DETAILS_ROUTE,
                canEdit: UserModel.getCurrentUser().hasAllPermissions('orders.update'),
                canEditShippingAddress: UserModel.getCurrentUser().hasAllPermissions('orders.update shipping address'),
                canEditBillingAddress: UserModel.getCurrentUser().hasAllPermissions('orders.update billing address'),
                canDecide: UserModel.getCurrentUser().hasAllPermissions('orders.decide'),
                canAutoDecide: UserModel.getCurrentUser().hasAllPermissions('orders.auto decide'),
            }),
        },
    };

    return routers;
};
